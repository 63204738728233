import { http } from "./config";
import querystring from "querystring";

export const userBalanceRequest = () => {
    return http.get('wallet/information')
};

export const paymentMethodsRequest = () => {
    return http.get('payment/payment-methods')
};

export const fetchUserCards = () => {
    return http.get('payment/cards')
};

export const depositRequest = (form) => {
    return http.post('payment', form);
};

export const transactionStatusRequest = (id) => {
    return http.get(`transaction/status/${id}`);
};

export const transactionsRequest = (params = {}) => {
    const queryParams = querystring.stringify(params);
    return http.get(`wallet/transactions?${queryParams}`);
};

export const withdrawalRequest = (form) => {
    return http.post(`wallet/withdrawal-request`, form);
};

export const cancelWithdrawalRequest = (id) => {
    return http.post(`wallet/withdrawal/${id}/cancel`, { id })
};
