import { combineReducers } from 'redux';
import gameData from './gameData';
import language from './language';
import cms from './cms';
import slider from './slider';
import bootstrap from './bootstrap';
import error from './error';
import registration from './registration';
import user from './user';
import global from './global';
import bank from './bank';

export default combineReducers({
    gameData,
    language,
    cms,
    slider,
    bootstrap,
    error,
    registration,
    user,
    bank,
    global
})
