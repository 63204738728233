import { sliderRequest } from '../../workers/slider';
import { consoleError } from '../../components/utils/useHandleError';

const GET_SLIDER_CONTENT = 'GET_SLIDER_CONTENT';
const GET_SLIDER_CONTENT_SUCCESS = 'GET_SLIDER_CONTENT_SUCCESS';
const GET_SLIDER_CONTENT_FAILED = 'GET_SLIDER_CONTENT_FAILED';
const INITIAL_STATE_SLIDER = 'INITIAL_STATE_SLIDER';

export const initialState = {
    data: {
        slider: []
    },
    isLoading: false,
    sliderIsLoaded: false
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case GET_SLIDER_CONTENT:
            return {
                ...state,
                isLoading: true
            };
        case GET_SLIDER_CONTENT_SUCCESS:
            return {
                ...state,
                data: {
                    slider: action.payload.data.sliders
                },
                sliderIsLoaded: true,
                isLoading: false
            };
        case GET_SLIDER_CONTENT_FAILED:
            return {
                ...state,
                isLoading: false
            };
        case INITIAL_STATE_SLIDER:
            return {
                ...state,
                sliderIsLoaded: false
            };
        default:
            return state
    }
}

export const getInitialSliderState = () => ({
    type: INITIAL_STATE_SLIDER
});

export const getContentForSlider = (lang) => {
    const submitting = () => ({
        type: GET_SLIDER_CONTENT
    });
    const contentForSlider = (payload) => ({
        type: GET_SLIDER_CONTENT_SUCCESS,
        payload
    });
    const fail = () => ({
        type: GET_SLIDER_CONTENT_FAILED
    });
    return dispatch => {
        dispatch(submitting());
        sliderRequest(lang)
            .then(res => dispatch(contentForSlider(res.data)))
            .catch((err) => {
                fail();
                consoleError(err);
            });
}};
