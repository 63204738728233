import { gameRequest, getGameUrlRequest } from '../../workers/games';
import { onLoading } from './bootstrap';
import { useLoadingPercent } from "../../components/utils/useLoadingPercent";
import { consoleError } from '../../components/utils/useHandleError';

const FETCH_GAMES = 'ALL_GAMES';
const FILTER_BY_CATEGORY = 'FILTER_BY_CATEGORY';
const FILTER_BY_SOFTWARE = 'FILTER_BY_SOFTWARE';
const FILTER_BY_SEARCH = 'FILTER_BY_SEARCH';
const SET_ACTIVE_FILTER_KEY = 'SET_ACTIVE_FILTER_KEY';
const GET_GAME_URL_LOADING = 'GET_GAME_URL_LOADING';
const GET_GAME_URL_SUCCESS = 'GET_GAME_URL_SUCCESS';

const filterGamesByCategory = (key, data, stateData) => {
    const getCategories = (key) => {
        switch(key) {
            case 'slots':
            case 'table':
                return data.categories.filter(category => stateData.categoriesKeys[key].some(key => category.name === key));
            case 'other':
                const values = Object.values(stateData.categoriesKeys).reduce((a, b) => [...a, ...b], []);
                return data.categories.filter(category => !values.some(key => category.name === key));
            default:
                return data.categories;
        }
    };

    switch(key) {
        case 'slots':
        case 'table':
        case 'other':
            const categories = getCategories(key);
            const gamesByCategory = data.games.filter(game => categories.some(category => category.id === game.categoryId));
            return {
                filteredGames: gamesByCategory,
            };
        case 'software':
        case 'search':
        default:
            return {
                filteredGames: data.games,
            };
    }
};

const initialState = {
    data: {
        games: [],
        softwares: [],
        categories: []
    },
    isGameListLoaded: false,
    failRequest: false,
    filteredGames: [],
    gamesPerPage: 24,
    initFilterKey: 'slots',
    activeFilterKey: 'slots',
    categoriesKeys: {
        slots: ['Slots'],
        table: ['Roulette', 'Blackjack', 'Poker', 'Craps', 'Baccarat'],
        video: ['Video poker'],
    },
    gameUrl: '',
    isGameUrlLoading: false,
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case FETCH_GAMES:
            const { data } = action.payload.data;
            const sortProvidersName = data.softwares.sort((a, b) => a.name.localeCompare(b.name));
            const allGames = [...data.games].map(item => {
                return {
                    ...item,
                    metadata: {
                        ...item.metadata,
                        updatedAt: item.metadata.updatedAt.replace(/-/g, "/"),
                        activatedAt: item.metadata.activatedAt.replace(/-/g, "/")
                    }
                }
            });
            const latestGames = allGames.sort((a, b) => new Date(b.metadata.updatedAt) - new Date(a.metadata.updatedAt));
            return {
                ...state,
                data: {
                    games: latestGames,
                    softwares: sortProvidersName,
                    categories: [...data.categories],
                },
                filteredGames: latestGames,
                isGameListLoaded: true
            };
        case FILTER_BY_CATEGORY:
            return {
                ...state,
                ...filterGamesByCategory(action.filterKey, state.data, state),
            };
        case FILTER_BY_SOFTWARE:
            const softwareList = action.softwareList.split(',').filter(item => item);
            const softwareIds = state.data.softwares.filter(software => softwareList.some(item => item === software.slug)).map(software => software.id);
            const gamesBySoftware = state.data.games.filter(game => softwareIds.includes(game.softwareId));
            const gamesList = softwareList.length ? gamesBySoftware : state.data.games;
            return {
                ...state,
                filteredGames: gamesList,
            };
        case FILTER_BY_SEARCH:
            const searchedGames = state.data.games.filter(game => game.name.toLocaleLowerCase().includes(action.searchValue.toLocaleLowerCase()));
            return {
                ...state,
                filteredGames: searchedGames,
            };
        case SET_ACTIVE_FILTER_KEY:
            return {
                ...state,
                activeFilterKey: action.payload,
            };
        case GET_GAME_URL_LOADING:
            return {
                ...state,
                isGameUrlLoading: true,
            };
        case GET_GAME_URL_SUCCESS:
            const responseData = action.payload.data;
            const gameUrl = Object.values(responseData.data)[0];
            return {
                ...state,
                isGameUrlLoading: false,
                gameUrl,
            };
        default:
            return state;
    }
};

export const getGameList = (isUserPage) => {
    const allGames = (res) => ({
        type: FETCH_GAMES,
        payload: res
    });
    return dispatch => {
        gameRequest()
            .then(res => {
                dispatch(allGames(res));
                dispatch(onLoading(useLoadingPercent(res.config.headers['Authorization'], 'games', isUserPage)));
            })
            .catch((err) => consoleError(err));
    }
};

export const filterByCategory = (filterKey) => ({
    type: FILTER_BY_CATEGORY,
    filterKey
});

export const filterBySoftware = (softwareList) => ({
    type: FILTER_BY_SOFTWARE,
    softwareList
});

export const filterBySearch = (searchValue) => ({
    type: FILTER_BY_SEARCH,
    searchValue
});

export const setActiveFilterKey = (payload) => ({
    type: SET_ACTIVE_FILTER_KEY,
    payload
});

export const getGameUrl = (lang, id, body) => {
    const loading = () => ({
        type: GET_GAME_URL_LOADING
    });
    const success = (payload) => ({
        type: GET_GAME_URL_SUCCESS,
        payload
    });
    return dispatch => {
        dispatch(loading());
        getGameUrlRequest(lang, id, body)
            .then(res => dispatch(success(res)))
            .catch((err) => consoleError(err));
    }
};
